@import './components/about_us_section';
@import './components/insights_section';
@import './components/our_services_section';
@import './components/banner';

.wide-section {
  &.home {
    padding-top: 10px;
  }
}

.section-header {
  margin: 0;
}

.view-all-button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.slant {
  height: 180px;
  transform: skewY(-3deg);
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  width: 100%;

  &.home-banner {
    background-color: $white-color;
    height: 100px !important;
    z-index: 1;
    margin-top: -100px;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: skewY(0deg);
  }

  &.accent {
    background-color: $accent-color;
    clip-path: polygon(0% 0, 0 100%, 100% 100%);
    z-index: 1;
    height: 109px !important;
    margin-top: -109px;
    width: 50% !important;
    transform: skewY(0deg);

    &.footer {
      z-index: -1;
      width: 100% !important;
      height: 180px !important;
      clip-path: polygon(0% 0, 0 100%, 100% 100%);
      transform: skewY(2deg);

    }

  }
}

.contact_us_section {
  margin-bottom: 60px;

  h1 {
    margin-bottom: -10px !important;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1400px) {
  .slant {
    &.accent {
      height: 135px;
      width: 25%;
    }

    &.home-banner {
      height: 104px;
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  .view-all-button {
    margin-left: 0 !important;
    width: 100% !important;
  }

  .slant {
    display: none;

    &.home-banner {
      background-color: $white-color;
      height: 104px;
      z-index: 1;
      margin-top: -38px;
    }
  }

  .view-all-button {
    margin-left: 0 !important;
    width: 100% !important;

  }

  .slant {
    height: 70px !important;

    &.accent {
      display: none;
    }
  }

}
