.slant {
  &.services {
    background: $accent-color;

    &.top {
      margin-top: 20px;
      height: 120px !important;
    }

    &.bottom {
      margin-top: -180px !important;
      height: 130px !important;

    }
  }
}

.services-section {
  margin-bottom: 120px;
  margin-top: 80px;
  background-color: $accent-color;

  .services-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 20px;

    .item {
      width: 45%;
      margin-bottom: 30px;
      background-color: $white-color;
      padding: 1.7%;
      text-decoration: none !important;
      border-radius: $standard-border-radius;

      .read-more-cta {
        text-decoration: none !important;
        font-style: italic;
      }

      .preview {
        height: 125px;
        overflow: hidden;

        p {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
        }

      }

      h3 {
        margin: 0;
      }

    }
  }
}

@media only screen and (max-width: $tablet-width) {
  .services-section {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 50px;

  }

  .services-container {
    .item {
      width: 100% !important;
      padding: 4% !important;
    }

    .preview {
      height: 120px !important;
    }
  }

}
