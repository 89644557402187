$short-banner-height: 200px;
$tall-banner-height: 580px;

.banner {
  height: $short-banner-height;
  width: 100%;
  background-size: cover;
  overflow: hidden;
  z-index: -1;

  img {
    width: 100%;
    height: $short-banner-height;
    object-fit: cover;
  }

  &.tall {
    height: $tall-banner-height;

    img {
      height: $tall-banner-height;
      object-fit: cover;
    }
  }

}
