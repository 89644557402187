.wide-section {
  &.contact {
    padding-top: 20px;

    h1 {
      margin-bottom: -15px;
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  .wide-section {
    &.contact {
      h1 {
        margin-bottom: 0 !important;
      }
    }
  }
}
