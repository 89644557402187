.dgg-banner-container {
  display: flex;
  margin-top: -590px;
  margin-bottom: 130px;
  justify-content: flex-end;
  align-items: center;
  padding-right: 7%;

  .copy {
    width: 38%;

    h1 {
      color: $light-white-color;
      text-align: left;

    }

    h2 {
      color: $light-white-color;
      font-family: 'Montserrat Regular';
      font-weight: normal;
      font-size: 32px !important;
      margin-top: -10px;
      margin-bottom: 40px;
      width: 80%;
    }
  }

  .banner-image {
    object-fit: contain;
    width: 50%;
    height: 480px;
  }

}

@media only screen and (max-width: $tablet-width) {
  .dgg-banner-container {
    margin-top: -540px;
    margin-bottom: 160px;
    justify-content: center;
    height: 400px;

    .banner-image {
      display: none;
    }

    .copy {
      width: 90% !important;

      h1 {
        text-align: left !important;
      }

      h2 {
        width: 100% !important;

      }
    }
  }
}
