.insights-preview-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 100px;
  flex-wrap: wrap;

  .insights-preview-image {
    width: 48%;
    max-width: 600px;
    height: 300px;
    overflow: hidden;
    border-radius: $standard-border-radius;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  .insights-preview-content {
    width: 48%;
    height: 300px;

    a {
      text-decoration: none;
    }

    .preview-text {
      height: 140px;

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }

    }

    .publish-date {
      font-style: italic;
    }

    .author-text {
      margin: 0 0 5px;
    }

    h3 {
      margin: 0;
    }

  }
}

@media only screen and (max-width: $tablet-width) {
  .flip {
    .insights-preview-image {
      position: relative;
      top: -330px;
    }

    .insights-preview-content {
      position: relative;
      bottom: -300px;
    }
  }

  .insights-preview-image {
    width: 100% !important;
  }

  .insights-preview-content {
    width: 100% !important;
    padding-top: 30px;

    .preview-text {
      height: auto !important;
    }
  }
}
