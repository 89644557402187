@import "./components/insight";

.wide-section {
  &.insights-page {
    margin-bottom: 100px;

    h1 {
      margin: 50px 0 -40px;
    }

    .card {
      display: none;
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  .wide-section {
    &.insights-page {
      padding-top: 80px!important;

      h1 {
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  .wide-section {
    &.insights-page {
      padding-top: 0 !important;

      .insights-preview-container {
        display: none;
      }

      .card {
        display: block;
      }
    }
  }

}
