.insights {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  a {
    text-decoration: none;
  }

  .item {
    background-color: $off-white-color;
    width: 30%;
    margin: 20px 1% 40px;
    border-radius: $standard-border-radius;

    .preview-image {
      width: 100%;
      height: 210px;
      border-top-left-radius: $standard-border-radius;
      border-top-right-radius: $standard-border-radius;

      img {
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-top-left-radius: $standard-border-radius;
        border-top-right-radius: $standard-border-radius;
      }
    }

    .preview-content {
      background-color: $light-white-color;
      width: 90%;
      padding: 0 5% 5%;
      height: 355px;
      border-radius: $standard-border-radius;
      overflow: hidden;

      h3 {
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
      }
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  .insights {
    .item {
      width: 45%;

      .preview-content {
        height: auto;

        h3 {
          -webkit-line-clamp: none !important;
          display: block;
        }

        p {
          -webkit-line-clamp: none !important;
          display: block;
        }
      }
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  .insights {
    .item {
      width: 100%;

    }
  }
}
