.go-back-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: -20px;
  padding-left: 4%;
  align-items: center;
  position: relative;
  bottom: -34px;
  cursor: pointer;

  img {
    width: 14px;
    position: relative;
    left: 0;
    transition: left 150ms linear;
  }

  span {
    font-family: 'Open Sans Regular';
    margin-left: 15px;
    font-size: 20px;
  }

  &:hover {
    img {
      left: -5px;
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  .go-back-container {
    // margin-top: 80px;
    // margin-bottom: -80px;
  }

}
