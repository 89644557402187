// CONTENT SECTIONS

body {
  margin: 0;
  background-color: $white-color;
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.wide-section {
  width: $desktop-wide-page-width;
  margin-left: calc((100% - #{$desktop-wide-page-width}) / 2);

  &.padded {
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

.narrow-section {
  width: $desktop-narrow-page-width;
  margin-left: calc((100% - #{$desktop-narrow-page-width}) / 2);

  &.padded {
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

.column-container {
  display: flex;
  justify-content: space-between;

  .column {
    margin-left: 2%;
    margin-right: 2%;
  }

  :first-child {
    margin-left: 0 !important;
  }

  :last-child {
    margin-right: 0 !important;
  }

  img {
    max-width: 100%;
    border-radius: $standard-border-radius;
  }
}

// TYPOGRAPHY
a,
h1,
h2,
h3,
h4,
h5,
p,
span {
  color: $primary-color;
}

h1 {
  font-family: 'Montserrat Bold' !important;
  font-size: 42px;
  text-align: center;
}

h2 {
  font-family: 'Open Sans Semi Bold' !important;
  font-size: 2pc;
  font-weight: bold;
}

h3,
h4,
h5 {
  font-family: 'Open Sans Semi Bold' !important;
  font-size: 1.2pc;
  font-weight: 1000 !important;
}

h3 {
  margin-bottom: -5px !important;
}

a,
p {
  font-family: 'Open Sans Regular' !important;
  font-size: 14px;
  line-height: $standard-line-height;
  font-weight: normal;
}

* {
  -webkit-font-smoothing: antialiased;
}

// BUTTONS

.button-link {
  text-decoration: none !important;
}

.primary-button {
  font-family: 'Open Sans Semi Bold' !important;
  border-radius: 0;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 180px;
  background-color: $secondary-color;
  color: white;
  font-size: 20px;
  line-height: $standard-line-height;
  text-align: center;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  height: 50px;
  -webkit-transition: background-color 150ms linear, box-shadow 150ms linear;
  -ms-transition: background-color 150ms linear, box-shadow 150ms linear;
  transition: background-color 150ms linear, box-shadow 150ms linear;

  &:hover {
    background-color: #0058A0 !important;
    box-shadow: $standard-box-shadow;
  }
}

.secondary-button {
  font-family: 'Open Sans Semi Bold' !important;
  border-radius: 0;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 150px;
  height: 40px;
  background-color: $secondary-color;
  color: white;
  font-size: 14px;
  line-height: $standard-line-height;
  text-align: center;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  -webkit-transition: background-color 150ms linear, box-shadow 150ms linear;
  -ms-transition: background-color 150ms linear, box-shadow 150ms linear;
  transition: background-color 150ms linear, box-shadow 150ms linear;

  &:hover {
    background-color: #0058A0 !important;
    box-shadow: $standard-box-shadow;
  }
}

.accent-button {
  font-family: 'Open Sans Semi Bold' !important;
  border-radius: 0;
  padding-top: 3px;
  padding-bottom: 3px;
  width: 180px;
  background-color: $pop-color;
  color: white;
  font-size: 20px;
  line-height: $standard-line-height;
  text-align: center;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  height: 50px;
  -webkit-transition: background-color 150ms linear, box-shadow 150ms linear;
  -ms-transition: background-color 150ms linear, box-shadow 150ms linear;
  transition: background-color 150ms linear, box-shadow 150ms linear;

  &:hover {
    background-color: darken($pop-color, 10%) !important;
    box-shadow: $standard-box-shadow;
  }
}

// CARDS

.card {
  box-shadow: (0px 1px 12px 1px rgba(0, 0, 0, 0.2));
  position: relative;
  top: 0;
  transition: top 100ms linear, box-shadow 100ms linear;

  &:hover {
    top: -2px;
    box-shadow: (0px 2px 12px 1px rgba(0, 0, 0, 0.2));

  }
}

// INPUTS

input {
  height: 40px;
  outline: none;
  border: solid 1.5px #909090 !important;
  font-family: 'Open Sans Regular' !important;
  font-size: 1pc;
  line-height: $standard-line-height;
  font-weight: normal;
  padding-left: 10px;
  border-radius: $standard-border-radius;
  background-color: $off-white-color;
  transition: border-color 200ms linear;

  &:focus {
    border-color: black !important;
  }

}

textarea {
  height: 120px;
  outline: none;
  border: solid 1.5px #909090;
  font-family: 'Open Sans Regular' !important;
  font-size: 1pc;
  line-height: $standard-line-height;
  font-weight: normal;
  padding-left: 10px;
  border-radius: $standard-border-radius;
  background-color: $off-white-color;
  transition: border-color 200ms linear;

  &:focus {
    border-color: black !important;
  }

}

// RESPONSIVE DESIGN

@media only screen and (max-width: $tablet-width) {
  .primary-button {
    width: 150px !important;
    height: 40px !important;
    font-size: 14px !important;
  }

  .narrow-section,
  .wide-section {
    width: $mobile-page-width;
    margin-left: calc((100% - #{$mobile-page-width}) / 2);
  }

  .column-container {
    display: block;

    .column {
      margin: 0 !important;
    }
  }

  h1 {
    font-size: 38px !important;
  }

  h2 {
    font-size: 28px !important;
  }

  h3 {
    font-size: 18px !important;
  }
}

@media only screen and (max-width: $mobile-width) {
  h1 {
    font-size: 36px !important;
  }

  h2 {
    font-size: 26px !important;
  }
}
