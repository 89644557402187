@media only screen and (max-width: $tablet-width) {
  .mobile-navbar {
    .mobile-menu {
      width: 100%;
      background-color: $primary-color;
      padding-left: 10px;

      h3 {
        margin-top: 25px;
        margin-bottom: 25px !important;

        &.active {
          text-decoration: underline;
        }
      }

      .tab-container {
        display: flex;
        padding-left: 10px;
        padding-right: 20px;
        align-items: center;
      }
    }
  }
}
