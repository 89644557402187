.insights-preview-section {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 80px;
  padding-top: 40px;
  justify-content: space-between;

  .mobile-view-all-button {
    margin-top: 40px;
  }

}
