// TYPOGRAPHY

@font-face {
  font-family: 'Montserrat Bold';
  src: url("../assets/fonts/Montserrat/Montserrat-Bold.woff") format('woff');
}
@font-face {
  font-family: 'Montserrat Regular';
  src: url("../assets/fonts/Montserrat/Montserrat-Regular.woff") format('woff');
}

@font-face {
  font-family: 'Open Sans Semi Bold';
  src: url("../assets/fonts/Open_Sans/OpenSans-SemiBold.woff") format('woff');
}

@font-face {
  font-family: 'Open Sans Regular';
  src: url("../assets/fonts/Open_Sans/OpenSans-Regular.woff") format('woff');
}

$standard-line-height: 26px;

$tall-line-height: 26px;

// BOX SHADOWS & BORDER RADIUS

$webkit-navigation-box-shadow: 0 1px 7px 1px rgba(0, 0, 0, 0.2);

$navigation-box-shadow: -1px 1px 16px -2px rgba(0,0,0,0.9);

$standard-box-shadow: 0 1px 7px 1px rgba(0, 0, 0, 0.2);

$standard-border-radius: 12px;

// NAVIGATION

$navigation-height: 90px;

$tranforming-menu-width-height: 30px;

// COLORS

$primary-color: #0B1D2C;

$secondary-color: #003969;

$accent-color: #F4EDE2;

$pop-color: #FF8C3E;

$off-white-color: #F5F5F5;

$light-white-color: #FAFAFA;

$white-color: #FFFFFF;

// PAGE

$desktop-wide-page-width: 77%;

$desktop-narrow-page-width: 67%;

$mobile-page-width: 90%;

// RESPONSIVE BREAKPOINTS

$mobile-width: 500px;

$tablet-width: 800px;

$desktop-width: 1100px;