.desktop-navbar {
  width: 100%;
  height: $navigation-height;
  display: flex;
  justify-content: space-between;
  background-color: $primary-color;
  align-items: center;
  opacity: 100%;
  position: static;
  z-index: 10000;

  a {
    color: $accent-color;
    text-decoration: none;
    display: block !important;

    .underline {
      height: 2px;
      background-color: $accent-color;
      color: white;
      width: 0;
      z-index: 1000;
      transition: width 0.3s;
      border-radius: 2px;

      &.active {
        width: 100% !important;
      }
    }

    &:hover {
      .underline {
        width: 100%;
      }
    }
  }

  .dgg-logo {
    text-align: center;
    padding-left: 70px;
    padding-top: 5px;
    width: 100px;
  }

  .item-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 50px;

    a {
      padding-left: 25px;
      padding-right: 25px;
      display: flex;
      justify-content: center;
      font-size: 18px;
      font-weight: bold;
      font-family: "Open Sans Semi Bold";

      .item {
        text-align: center;
        cursor: pointer;
        border-bottom: solid 2px transparent;
      }
    }

  }
}

@media only screen and (max-width: $tablet-width) {
  .desktop-navbar {
    display: none;
  }
}
