.contact-form-container {
  .form {
    width: 65%;

    input,
    textarea {
      width: 99%;
      min-width: 99%;
      max-width: 99%;
    }

    label {
      display: flex;
      justify-content: space-between;
      text-align: center;

      span {
        margin-top: 25px;
        font-family: 'Open Sans Regular';
        color: #DD0000;
        font-size: 14px;
      }

      p {
        margin-top: 20px;
        margin-bottom: 5px;
      }
    }

    .required {
      color: #DD0000;
    }

    .required-fields {
      text-align: right;
      margin-top: 0;
      margin-bottom: -10px;
    }
  }

  .send-message-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;

    .primary-button {
      margin-top: 20px;
    }

    .loader {
      margin-top: 18px;
      animation: spin 0.4s infinite linear;
      margin-left: -30px;
      position: relative;
      right: -40px;
      width: 30px;

    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  .form {
    width: 100% !important;
    margin-bottom: 20px;
  }

}

@media only screen and (max-width: $mobile-width) {
  input,
  textarea {
    max-width: 96% !important;
    min-width: 96% !important;
  }

}
