@import "./desktop/desktop";
@import "./mobile/mobile";
@import "./mobile/menu";
@import "./go_back/go_back";

.mobile-navbar {
  display: none;
  position: static;
  z-index: 1000;
}
