@import "./components/form_inputs";

.contact-form-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 100px;

  .confirmation {
    width: 65%;
    background-color: $off-white-color;
    box-shadow: $standard-box-shadow;
    align-self: flex-start;
    border-radius: $standard-border-radius;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;

    &.mobile {
      display: none;
    }

    &.desktop {
      margin-top: 55px;

    }

    p {
      line-height: $tall-line-height;
      padding: 0;
    }

    h3 {
      text-align: center;
      padding: 10px 0 0;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  .confirmation {
    width: 100% !important;
    margin-top: 15px !important;

    &.desktop {
      margin-top: 40px !important;
    }

  }

}

@media only screen and (max-width: $mobile-width) {
  .name-input-container {
    .first-name-container {
      width: 100% !important;
    }

    .last-name-container {
      width: 100% !important;
    }
  }

  .confirmation {
    &.mobile {
      display: block;

      p {
        margin-bottom: 0;
      }
    }

    &.desktop {
      display: none;
    }
  }
}
