.service-preview-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  margin-top: 50px;
  flex-wrap: wrap;

  .service-preview-image {
    width: 48%;
    max-width: 580px;
    height: 300px;
    overflow: hidden;
    border-radius: $standard-border-radius;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .service-preview-content {
    width: 48%;
    height: 300px;

    h2 {
      font-size: 32px !important;
      font-family: 'Montserrat Bold' !important;
    }

    a {
      text-decoration: none;
    }

    .preview-text {
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
      }

    }

  }
}

@media only screen and (max-width: $tablet-width) {
  .flip {
    .service-preview-content {
      position: relative;
      bottom: -300px;
    }

    .service-preview-image {
      position: relative;
      top: -300px;
      text-align: center;
    }
  }

  .service-preview-container {
    margin-bottom: 10px;
    margin-top: 0;
  }

  .service-preview-image {
    width: 100% !important;
  }

  .service-preview-content {
    width: 100% !important;
    padding-top: 0;

    .preview-text {
      height: auto !important;
    }
  }
}
