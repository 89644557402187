.post-content {
  margin-bottom: 100px;
  width: 100%;

  .posted-on {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin: 0;
  }

  .written-by {
    text-align: center;
    font-size: 20px;
    margin-top: 0;

  }

  img {
    max-width: 100%;
    border-radius: $standard-border-radius;
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  p {
    line-height: $tall-line-height;
  }

  h2,
  h3,
  h4,
  h5 {
    padding-top: 40px !important;
  }
}

.related-insights-container {
  margin-bottom: 100px;
  padding-bottom: 50px;
  padding-top: 20px;

  .section-header {
    width: $desktop-wide-page-width;
    margin-left: calc((100% - #{$desktop-wide-page-width}) / 2);
  }
}

@media only screen and (max-width: $tablet-width) {
  .narrow-section {
    &.post {
      padding-top: 100px !important;
    }
  }

  .related-insights-container {
    .section-header {
      width: $mobile-page-width !important;
      margin-left: calc((100% - #{$mobile-page-width}) / 2) !important;
    }
  }

  .post-content {
    img {
      max-width: 100% !important;
    }
  }

  .column-container {
    .column {
      width: 100% !important;
    }
  }
}
