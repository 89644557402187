@import "./components/service";

.services-page-content {
  padding-bottom: 100px;
  padding-top: 20px;
}

@media only screen and (max-width: $tablet-width) {
  .services-page-content {
    padding-top: 20px;

  }

}
