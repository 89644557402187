.footer {
  background-color: $primary-color;
  padding-top: 40px;
  padding-bottom: 20px;
  width: 100%;

  .desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .mobile {
    display: none;
  }

  a,
  h3,
  h4,
  h5,
  p {
    color: $white-color;
    text-decoration: none;
  }

  .legal-footer-container {
    width: 21%;

    .dgg-logo {
      width: 90px;
      margin-left: -8px;
      margin-bottom: -5px;
    }

    h3 {
      font-size: 13px !important;
      font-family: 'Open Sans Semi Bold' !important;
    }

    p {
      font-size: 12px;
    }
  }

  .contact-footer-container {
    width: 19%;
    word-wrap: break-word;

    h3 {
      font-size: 14px !important;
    }

    p {
      font-size: 12px;
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }

    h3 {
      margin-bottom: 12px !important;
    }

  }

  .services-footer-container {
    width: 17%;

    h4 {
      margin-top: 15px;
      margin-bottom: 10px !important;
      font-size: 14px;
      text-align: left;

    }

    p {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }

    :last-child {
      margin-bottom: 30px;
    }
  }

  .tabs-footer-container {
    p {
      margin-top: 10px !important;
      margin-bottom: 10px !important;
      font-family: 'Open Sans Semi Bold' !important;

    }
  }
}

@media only screen and (max-width: $tablet-width) {
  .legal-footer-container {
    width: 40% !important;
  }

  .contact-footer-container {
    width: 40% !important;
  }

  .services-footer-container {
    width: 40% !important;
  }

  .tabs-footer-container {
    width: 40% !important;
  }

}

@media only screen and (max-width: $mobile-width) {
  .mobile {
    display: flex !important;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 30px;

    .image-footer-container {
      width: 40% !important;
      text-align: center;

      img {
        width: 100px !important;
      }
    }

    .contact-footer-container {
      width: 60% !important;

      h3 {
        font-family: 'Open Sans Regular' !important;
      }
    }

  }

  .desktop {
    display: none !important;
  }

}
