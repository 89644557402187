.mobile-navbar {
  display: none;
}

@media only screen and (max-width: $tablet-width) {
  .mobile-navbar {
    width: 100%;
    opacity: 100%;
    display: block !important;
    height: $navigation-height;
    overflow: hidden;
    transition: height 0.3s ease-in-out;
    background-color: $primary-color;

    h3 {
      color: $white-color;
    }

    &.expanded {
      height: 400px !important;
    }

    .mobile-navbar-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: $navigation-height;
      width: 100%;
    }

    .dgg-logo {
      text-align: center;
      width: 90px;
      margin-left: 10px;
    }

    .transforming-button {
      padding-right: 20px;
      cursor: pointer;

      .mobile-hamburger-button {
        width: $tranforming-menu-width-height;
        height: $tranforming-menu-width-height;
      }

      .mobile-x-button {
        width: $tranforming-menu-width-height;
        height: $tranforming-menu-width-height;
      }
    }
  }
}
