.staff-previews-container {
  .staff-preview-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 60px;
    margin-top: 60px;

    .staff-preview-image {
      height: 272px;
      width: 250px;
      text-align: center;
      margin-right: auto;
      margin-left: auto;

      img {
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .staff-preview-content {
      width: 69%;

      h3 {
        margin: 0;
      }

      .preview-text {
        p {
          height: 100px;
          margin-bottom: 20px;
          overflow: hidden;
          margin-top: 0;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;

        }
      }
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  .staff-previews-container {
    .staff-preview-container {
      margin-bottom: 25px !important;
      margin-top: 25px !important;

      .staff-preview-image {
        width: 70% !important;
        height: 60% !important;
        text-align: center;

        img {
          width: 60% !important;
          object-fit: cover;
          margin-top: 10%;
        }

      }

      .staff-preview-content {
        width: 100% !important;
        margin-top: 30px;
      }
    }

  }

}

@media only screen and (max-width: $mobile-width) {
  .staff-previews-container {
    .staff-preview-container {
      margin-bottom: 25px !important;
      margin-top: 25px !important;

      .staff-preview-image {
        width: 100% !important;
        height: 30% !important;
        margin-left: 0 !important;

        img {
          width: 60% !important;
          object-fit: cover;
          margin-top: 10%;
        }

      }

      .staff-preview-content {
        width: 100% !important;
        margin-top: 30px;

        .cta-button-container {
          text-align: center !important;
          display: inline-block;
          width: 100%;
        }
      }
    }
  }
}
