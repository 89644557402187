.staff-profile-container {
  margin-bottom: 150px;
  margin-top: 20px;

  h1 {
    text-align: left;
  }

  .staff-profile-image {
    width: 300px;
    height: 300px;
    margin-right: 30px;
    display: inline-block;
    float: left;
    text-align: center;
    padding-bottom: 10px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .staff-profile-content {
    p {
      text-align: justify;
      line-height: $tall-line-height;
    }

    h3 {
      margin: 0;
    }
  }
}

@media only screen and (max-width: $tablet-width) {
  h1 {
    text-align: center !important;
  }

  h4 {
    text-align: center;

  }

  .narrow-section {
    &.profile {
      padding-top: 30px;
    }
  }

  .staff-profile-container {
    margin-top: 0 !important;
  }

  .staff-profile-image {
    width: 100% !important;
    height: 100% !important;

    img {
      width: 40% !important;
      height: 40% !important;
    }
  }
}

@media only screen and (max-width: $mobile-width) {
  .staff-profile-image {
    width: 70% !important;
    margin-left: 15%;
    margin-top: 15px;

    img {
      width: 90% !important;
      height: 90% !important;
    }
  }
}
