.about-us-container {
  margin-bottom: 50px;
  margin-top: 10px;

  h1 {
    margin: 0 !important;
  }

  p {
    line-height: $tall-line-height !important;

  }

  .column-container {
    align-items: center;
  }
}
